var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", lg: "12", xl: "12" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c("b-card-body", { staticClass: "p-4" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.validateBeforeSubmit($event)
                      }
                    }
                  },
                  [
                    _c("h1", [_vm._v("Add New Company")]),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v("Enter company information")
                    ]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Company Name")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "name",
                                    placeholder: "Company Name"
                                  },
                                  model: {
                                    value: _vm.name,
                                    callback: function($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("name"),
                                  expression: "errors.has('name')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("name"),
                                    expression: "errors.has('name')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The company name is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [
                              _vm._v("Registration Company Number")
                            ]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "rcn",
                                    placeholder: "Registration Company Number"
                                  },
                                  model: {
                                    value: _vm.rcn,
                                    callback: function($$v) {
                                      _vm.rcn = $$v
                                    },
                                    expression: "rcn"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("rcn"),
                                  expression: "errors.has('rcn')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("rcn"),
                                    expression: "errors.has('rcn')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The company number is required.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("GST Number")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "gst",
                                    placeholder: "GST Number"
                                  },
                                  model: {
                                    value: _vm.gst,
                                    callback: function($$v) {
                                      _vm.gst = $$v
                                    },
                                    expression: "gst"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("gst"),
                                  expression: "errors.has('gst')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("gst"),
                                    expression: "errors.has('gst')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The GST number is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Website")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "url",
                                      expression: "'url'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "portal_address",
                                    placeholder: "Website Address"
                                  },
                                  model: {
                                    value: _vm.portal_address,
                                    callback: function($$v) {
                                      _vm.portal_address = $$v
                                    },
                                    expression: "portal_address"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("portal_address"),
                                  expression: "errors.has('portal_address')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("portal_address"),
                                    expression: "errors.has('portal_address')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The url is not valid.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Address Line 1")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "address",
                                    placeholder: "Address Line 1"
                                  },
                                  model: {
                                    value: _vm.address,
                                    callback: function($$v) {
                                      _vm.address = $$v
                                    },
                                    expression: "address"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("address"),
                                  expression: "errors.has('address')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("address"),
                                    expression: "errors.has('address')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The address is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Address Line 2")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "address2",
                                    placeholder: "Address Line 2"
                                  },
                                  model: {
                                    value: _vm.address2,
                                    callback: function($$v) {
                                      _vm.address2 = $$v
                                    },
                                    expression: "address2"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("City")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "city",
                                    placeholder: "City"
                                  },
                                  model: {
                                    value: _vm.city,
                                    callback: function($$v) {
                                      _vm.city = $$v
                                    },
                                    expression: "city"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("city"),
                                  expression: "errors.has('city')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("city"),
                                    expression: "errors.has('city')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The city is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Province")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "select",
                                    options: _vm.provinceOptions,
                                    name: "province",
                                    placeholder: "Province"
                                  },
                                  model: {
                                    value: _vm.province,
                                    callback: function($$v) {
                                      _vm.province = $$v
                                    },
                                    expression: "province"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("province"),
                                  expression: "errors.has('province')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("province"),
                                    expression: "errors.has('province')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The province is required.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Postal Code")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-building" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "postalcode",
                                    placeholder: "Postal Code"
                                  },
                                  model: {
                                    value: _vm.postalcode,
                                    callback: function($$v) {
                                      _vm.postalcode = $$v
                                    },
                                    expression: "postalcode"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("postalcode"),
                                  expression: "errors.has('postalcode')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("postalcode"),
                                    expression: "errors.has('postalcode')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The postal code is required.")]
                            )
                          ],
                          1
                        ),
                        _c("b-col", { staticClass: "col-12 col-lg-6" })
                      ],
                      1
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.error,
                            expression: "error"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v("Error. Please contact admin.")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-4",
                        attrs: { variant: "success", type: "submit", block: "" }
                      },
                      [_vm._v("Create Company")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-row>
    <b-col sm="12" lg="12" xl="12">
      <b-card no-body class="">
        <b-card-body class="p-4">
          <form @submit.prevent="validateBeforeSubmit">
            <h1>Add New Company</h1>
            <p class="text-muted">Enter company information</p>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Company Name</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-validate="'required'" name="name" v-model="name" class="form-control" placeholder="Company Name" />
                </b-input-group>
                <i v-show="errors.has('name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('name')" class="help is-danger">The company name is required.</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Registration Company Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="rcn" v-validate="'required'" name="rcn" class="form-control" placeholder="Registration Company Number" />
                </b-input-group>
                <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>GST Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="gst" v-validate="'required'" name="gst" class="form-control" placeholder="GST Number" />
                </b-input-group>
                <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('gst')" class="help is-danger">The GST number is required.</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Website</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="portal_address" name="portal_address" class="form-control" v-validate="'url'" placeholder="Website Address" />
                </b-input-group>
                <i v-show="errors.has('portal_address')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('portal_address')" class="help is-danger">The url is not valid.</span>
              </b-col>
            </b-row>
            <hr>

            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Address Line 1</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="address" v-validate="'required'" name="address" class="form-control" placeholder="Address Line 1" />
                </b-input-group>
                <i v-show="errors.has('address')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('address')" class="help is-danger">The address is required.</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Address Line 2</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="address2" name="address2" class="form-control" placeholder="Address Line 2" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>City</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="city" v-validate="'required'" name="city" class="form-control" placeholder="City" />
                </b-input-group>
                <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Province</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select type="select" :options="provinceOptions" v-model="province" v-validate="'required'" name="province" class="form-control" placeholder="Province"/>
                </b-input-group>
                <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required.</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Postal Code</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="postalcode" v-validate="'required'" name="postalcode" class="form-control" placeholder="Postal Code" />
                </b-input-group>
                <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
              </b-col>
              <b-col class="col-12 col-lg-6">

              </b-col>
            </b-row>

            <!-- <hr>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Phone</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
                </b-input-group>
                <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Fax</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="fax" name="fax" class="form-control" placeholder="Fax Number" />
                </b-input-group>
              </b-col>
            </b-row>

            <hr> -->

            <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="help is-danger">Error. Please contact admin.</span>

            <b-button variant="success" class="mt-4" type="submit" block>Create Company</b-button>
          </form>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>


<script>
export default {
  name: 'AddCompany',
  data: function () {
    return {
      name: "",
      address: "",
      address2: "",
      portal_address: "",
      rcn: "",
      gst: "",
      city: "",
      postalcode: "",
      province: "Alberta",
      fax: null,
      phone: null,
      error: false,
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta'},
        { value: "British Columbia", text: 'British Columbia'},
        { value: "Manitoba", text: 'Manitoba'},
        { value: "New Brunswick", text: 'New Brunswick'},
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador'},
        { value: "Nova Scotia", text: 'Nova Scotia'},
        { value: "Ontario", text: 'Ontario'},
        { value: "Prince Edward Island", text: 'Prince Edward Island'},
        { value: "Quebec", text: 'Quebec'},
        { value: "Saskatchewan", text: 'Saskatchewan'},
        { value: "Northwest Territories", text: 'Northwest Territories'},
        { value: "Nunavut", text: 'Nunavut'},
        { value: "Yukon", text: 'Yukon'}
      ]
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            name: this.name,
            addressline1: this.address,
            addressline2: this.address2,
            portal_address: this.portal_address,
            rcn: this.rcn,
            gst: this.gst,
            city: this.city,
            province: this.province,
            postalcode: this.postalcode,
            // fax: this.fax,
            // phone: this.phone
          })
          this.$axios.post('/admin/create/company', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Company Added Successful.', {type: 'success', duration: '3000'})
              this.$router.push('/admin/all-companies')
            }
          })
          .catch(error => {
            this.$toasted.show('Error.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    }
  }
}
</script>

<style lang="scss">
  label {
    clear:both;
    display:block;
  }
  .fa-exclamation-triangle {
    color: #ffc107;
    margin-right: 0.5em;
  }
</style>
